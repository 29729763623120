<template>
  <section id="hero">
    <v-img :min-height="minHeight" :src="require('@/assets/backgry.jpg')" class="white--text"
      gradient="to right, rgba(5, 11, 31, .7), rgba(5, 11, 31, .3)">
      <v-container class="fill-height px-4 py-12">
        <v-responsive class="d-flex align-center" height="100%" max-width="700" width="100%">
          <base-heading title="JACK SLATTERY" />

          <!-- <base-body>
            Current Projects:
          </base-body>
          <base-body>
            Get your ass up and fuck!
          </base-body> -->

          <!-- <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <v-btn @click="dosomething">
              Discover More
            </v-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
            >
              Get Started Now
            </base-btn>
          </div> -->
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: 'SectionHero',

  provide: {
    theme: { isDark: true },
  },
  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

      return `calc(${height} - ${this.$vuetify.application.top}px)`
    },
  },

  methods: {
    dosomething: function (event) {

    },
  },
}
</script>
